.text-active {
  color: #d4ae60 !important;
  border-bottom: 2px solid #d4ae60;
  -webkit-transition: border-color 1s ease;
  -moz-transition: border-color 1s ease;
  -o-transition: border-color 1s ease;
  -ms-transition: border-color 1s ease;
  transition: border-color 1s ease;
  border-color: #d4ae60;
}

.text-hover:hover {
  border-bottom: 2px solid white;
  -webkit-transition: border-color 1s ease;
  -moz-transition: border-color 1s ease;
  -o-transition: border-color 1s ease;
  -ms-transition: border-color 1s ease;
  transition: border-color 1s ease;
  color: #d4ae60 !important;
  border-color: #081a28;
}

.read-or-hide {
  color: #d4ae60;
  cursor: pointer;
}

.link-href:hover {
  color: #d4ae60 !important;
}
